<template>
    <div>
        <headTitle/>
        <img class="mobile-example-headImg" src="../../assets/image/mobile/03-6Dashboard数据实施.png"/>
        <div class="mobile-head-explain">
            <div class="mobile-head-explain-title">Dashboard数据实施</div>
            <div class="mobile-head-explain-content">全球某知名乳制品公司</div>
        </div>
        <div class="description">
            <div class="title1">项目介绍</div>
            <div v-if="!showDescription" class="title2" @click="(showDescription = !showDescription)">展开<i class="el-icon-arrow-down"></i></div>
            <div v-if="showDescription" class="title2" @click="(showDescription = !showDescription)">收起<i class="el-icon-arrow-up"></i></div>
        </div>
        <div style="margin-top: 2rem;">
            <el-collapse-transition>
                <div v-if="showDescription">
                    <div class="description-box">近几年，客户公司在中国市场一直保持着高速增长，业务发展迅猛，对数据需求越来越高。但是在数据能力
                方面较为薄弱，系统数据不规范，手工数据繁多，传统EXCEL形式的企业报表和指标已经满足不了现有的分析需求。随着业务的快速发展，及时、可靠
                的数据获取和分析能力，以及完善、高效的报表和KPI管理，成为面临的重要问题。通过整合Dashboard业务指标依赖的多数据源以及设计企业数据仓
                库的架构与模型，从而实现复杂指标计算，更直观展示数据变化。</div>
                </div>
            </el-collapse-transition>
        </div>
        <div class="mobile-head-catalogue" style="margin-top:5.7rem">
            <div :class="{'title1':true,'ischecked':checkIndex==1} " @click="(checkIndex=1)"><span>业务痛点</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==2} " @click="(checkIndex=2)"><span>实施方案</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==3} " @click="(checkIndex=3)"><span>实施收益</span></div>
        </div>
        <div v-if="(checkIndex==1)" class="mobile-content-box1">
            <div class="ware-box1">
                <div class="title1">
                    业务发展迅猛，数据量庞大
                </div>
            </div>
            <div class="ware-box1">
                <div class="title1">
                    数据能力薄弱，系统数据不规范
                </div>
            </div>
            <div class="ware-box1" style="margin-bottom:5rem">
                <div class="title1">
                    手工数据繁多，耗时耗力，人工成本巨大
                </div>
            </div>
        </div>
        <div v-else-if="(checkIndex==2)" class="mobile-content-box1">
            <div class="purchase-box">
                <div class="card">
                    <img class="logo" src="../../assets/image/selected/03-2-5组件.svg"/>
                    <div>
                        <div class="title1">组件  </div>
                        <div class="title2">采用了亚马逊云科技大数据平台S3、Redshift、RDS、EC2等组件，在数据清洗、指标计算时，获得了较大的算力，数据时效性获得了极大的提升 </div>
                    </div>
                </div>
                <div class="card">
                    <img class="logo" src="../../assets/image/selected/03-2-5分析.svg"/>
                    <div>
                        <div class="title1">分析</div>
                        <div class="title2">通过全面分析来自客户市场、销售、品牌、经销商等业务分析需求，设计和实施数据模型和提供逐步实施的方法与建议</div>
                    </div>
                </div>
                <div class="card">
                    <img class="logo" src="../../assets/image/selected/03-2-5自助分析.svg"/>
                    <div>
                        <div class="title1">自助分析</div>
                        <div class="title2">整合内部系统和外部手工数据，满足业务用户自定义获取和查询业务数据能力，完成自助分析</div>
                    </div>
                </div>
                <div class="card">
                    <img class="logo" src="../../assets/image/selected/03-2-5tableau.svg"/>
                    <div>
                        <div class="title1">tableau进行报表设计与开发</div>
                        <div class="title2">通过业务部门选定的最关切的BI分析指标，借助tableau进行报表设计与开发，完成报表展示以及复杂指标计算</div>
                    </div>
                </div>
                <div class="card">
                    <img class="logo" src="../../assets/image/selected/03-2-5数据清洗.svg"/>
                    <div>
                        <div class="title1">数据清洗转换</div>
                        <div class="title2">手工数据繁多，数据格式和内容不规则，通过数据清洗转换，有效提升数据质量，更好地服务于业务决策</div>
                    </div>
                </div>
                <div style="width:34.8rem;height:21rem">
                </div>
            </div>
        </div>
        <div v-else-if="(checkIndex==3)" class="mobile-content-box1">
            <div class="purchase-title" style="display:flex;padding-top: 3rem;"><div class="purchase-doit">●</div><div class="special-style">数据平台设计与开发方便数据管理，帮助客户整合；</div></div>
            <div class="purchase-title" style="display:flex;"><div class="purchase-doit">●</div><div class="special-style">企业数据仓库的架构与模型使客户能支持业务需求的快速变化；</div></div>
            <div class="purchase-title" style="display:flex;"><div class="purchase-doit">●</div><div class="special-style">减少数据的复杂性，让客户能够轻松的提取和使用数据；</div></div>
            <div class="purchase-title" style="display:flex;margin-bottom: 5rem;"><div class="purchase-doit">●</div><div class="special-style">制作Dashboard，完成复杂指标计算，更直观展示数据变化。</div></div>
                
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileIndex',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            checkIndex: 1,
            showDescription:true,
            box2ImageList:[
                {
                    index:0,
                    urlImg:require("../../assets/image/index/01-15GF整合数据分析系统.png"),
                    title:'5GF整合数据分析系统',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-5GF'
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/index/01-1数字化产线.png"),
                    title:'数字化产线', //右转是0
                    content:'打造透明、高效、稳定的数字化生产管理平台',
                    route:'/selected-line'
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/index/01-1市场渗透率分析.png"),
                    title:'市场渗透率分析',
                    content:'全球某化妆品巨头',
                    route:'/selected-marykay'
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/index/01-1智能仓储.png"),
                    title:'智能仓储',
                    content:'布局原料赋码，助力品质生产',
                    route:'/selected-warehousing'
                },
                {
                    index:4,
                    urlImg:require("../../assets/image/index/01-1Dashboard数据实施.png"),
                    title:'Dashboard数据实施',
                    content:'全球某知名乳制品公司',
                    route:'/selected-dashboard'
                },
                {
                    index:5,
                    urlImg:require("../../assets/image/index/01-1商情诊断管理分析.png"),
                    title:'商情诊断管理分析',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-business'
                },
                {
                    index:6,
                    urlImg:require("../../assets/image/index/01-1iDAS整合数据分析系统.png"),
                    title:'iDAS整合数据分析系统', //左转是0
                    content:'韩国某知名化妆品集团',
                    route:'/selected-iDAS'
                },

            ],
        };
    },

    mounted() {
    },

    methods: {
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        }
    },
};
</script>

<style lang="less" scoped>
.description{
    display: flex;
    width: 66.5rem;
    margin: auto;
    margin-top: 6rem;
    .title1{
        font-size: 3.3rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #11A84F;
        line-height: 4.2rem;
    }
    .title2{
        font-size: 2.8rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 4.2rem;
        margin-left: 65%;
    }
}
.description-box{
    max-width: 66.5rem;
    margin: auto;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 5rem;
}
.mobile-head-catalogue{
    width: 100%;
    height: 9rem;
    display: flex;
    background: #F8F8F8;
    .title1{
        width: 20rem;
        height: 9rem;
        display: flex;
        text-align: center;
        align-items: center;
        span{
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #4F4F4F;
            line-height: 2rem;
            margin: auto;
        }
    }
    .ischecked{
        background: #EDEDED;
        border-bottom: 2px solid #11A84F;
    }
}
.mobile-content-box1{
    width: 66rem;
    margin: auto;
    margin-top: 3rem;
}
.ware-content1{
    max-width: 66rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;
}
.ware-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;
}
.ware-box1{
    width: 66.8rem;
    height: 10rem;
    border: 1px solid #CCCCCC;
    margin: auto;
    margin-top: 1.3rem;
    display: flex;
    .title1{
        width: 58.8rem;
        // height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 4rem; 
        margin: auto; 
        align-items: center;
    }
}
.ware-img{
    width: 66.5rem;
    height: 43.5rem;
}
.ware-img2{
    width: 66.5rem;
    height: 28rem;
}
.purchase-box{
    width: 70rem;
    margin-left: -2rem;
    // height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5rem;
    .card{
        width: 60rem;
        height: 35rem;
        margin: auto;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(219,219,219,0.5);
        border-radius: 2px;
        // justify-content: center;
        margin-top: 2rem;
        display: flex;
        .logo{
            width: 6rem;
            height: 6rem;
            margin: 8.2rem 0 0 4rem;
        }
        .title1{
            // height: 2rem;
            width: 100%;
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #333333;
            line-height: 5rem;

            margin: 7rem 0 0 1.2rem;
        }
        .title2{
            max-width: 45rem;
            // height: 4.8rem;
            font-size: 2.8rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #666666;
            line-height: 4rem;

            margin: 0rem 0 0 1.2rem;
        }
    }
}
.special-style{
    max-width: 66.5rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;
    display: flex;
    .title-6{
        text-align: center;
        margin: auto;
        width: 5rem;
        height: 5rem;
        font-size: 4rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #ED5847;
        line-height: 3rem;
        margin-top: -0.5rem;
    }
}
.purchase-title{
    max-width: 66.5rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;
    margin-top: 1.5rem;
}
.purchase-content{
    max-width: 66.5rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;

    margin: 1.3rem 0 0 0.2rem;
}
.purchase-doit{
    color: #612177;
    font-size: 1rem;
    transform: scale(0.8);
    margin-right: 1.4rem;
}
</style>